import Sentry from '$lib/sentry'

/**
 * @type {import('@sveltejs/kit').HandleClientError}
 * @param {Record<string, any>} object
 * @returns {Promise<{code: string; message: string;}>}
 */
export const handleError = async ({ error, event }) => {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  Sentry.captureException(error, { event })
  return {
    code: error.code ?? '500',
    message: error.message,
  }
}
