import * as client_hooks from '../../../src/hooks.client.js';

export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23')
];

export const server_loads = [0,3];

export const dictionary = {
		"/(landing)": [~5,[2]],
		"/(products)/apply": [~6,[3],[4]],
		"/(products)/borrow/balances": [~7,[3],[4]],
		"/(products)/borrow/balances/archive": [~8,[3],[4]],
		"/(products)/borrow/new": [~9,[3],[4]],
		"/(products)/borrow/pools": [~10,[3],[4]],
		"/(products)/borrow/pools/archive": [~13,[3],[4]],
		"/(products)/borrow/pools/[poolId]": [~11,[3],[4]],
		"/(products)/borrow/pools/[poolId]/lenders": [~12,[3],[4]],
		"/(products)/dashboard": [~14,[3],[4]],
		"/(products)/faucet": [~15,[3],[4]],
		"/(products)/lend/pools": [~16,[3],[4]],
		"/(products)/lend/pools/archive": [~18,[3],[4]],
		"/(products)/lend/pools/[poolId]": [~17,[3],[4]],
		"/(products)/lend/positions": [~19,[3],[4]],
		"/(products)/lend/positions/archive": [~20,[3],[4]],
		"/(products)/profiles": [~21,[3],[4]],
		"/(products)/profiles/[slug]": [~22,[3],[4]],
		"/(products)/transactions": [~23,[3],[4]]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),
};

export { default as root } from '../root.svelte';