import * as Sentry from '@sentry/svelte'
import { BrowserTracing } from '@sentry/browser'

import config, { isDev } from '$lib/config'

// Initialize the Sentry
Sentry.init({
  environment: config.env,
  enabled: !isDev,
  dsn: 'https://a9a850f9346446bab5f63366fb0c64f7@o1202606.ingest.sentry.io/4504173156106240',
  integrations: [new BrowserTracing()],
  tracesSampleRate: config.env === 'production' ? 0.2 : 1.0,
})

export default Sentry
